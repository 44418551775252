import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import sample from "./img/vid.mp4";

// Style
import "style/index.scss";
import s from "./index.module.scss";

const App = () => {
  return (
    <div className={s.home}>
      <div className={s.container}>
        <h1>
          Feelings are <span style={{ fontStyle: "italic" }}>important!</span>
        </h1>
        <h2>and so are you!</h2>
        <p>Just like healing, building a website takes time!</p>
        <p>
          If you're interested in starting your therapeutic journey, you can
          reach out to{" "}
          <a href="mailto:erin@feeltripcounseling.com">
            erin@feeltripcounseling.com
          </a>
        </p>
        <p>Thanks for stopping by!</p>
      </div>
      <svg
        className={s.logo}
        width="300"
        height="108"
        viewBox="0 0 400 144"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M35.4902 59.5595C35.3695 57.7496 36.0935 56.9049 38.2051 55.638C39.9547 54.6123 40.6184 53.7677 40.92 50.0874C41.1614 45.9849 41.0407 37.0558 40.92 33.3755C40.7391 30.2383 40.3771 29.3333 38.6878 28.549C36.9381 27.644 35.4902 27.463 35.4902 24.7481C35.4902 21.3695 37.1191 20.3439 40.6184 20.3439C46.2292 20.3439 53.3484 20.7662 60.8296 20.5249C63.2428 20.5249 64.4495 19.8612 66.5611 19.8612C69.7587 19.8612 70.724 21.0075 72.6546 26.2564C73.7406 29.273 74.4645 31.3846 74.4645 32.8929C74.4042 34.9442 73.1976 35.9095 70.3016 36.2111C67.647 36.4525 66.5611 34.7028 64.9321 30.9019C63.3032 27.282 63.0618 27.5233 57.3906 27.5233C49.7285 27.5233 50.4525 27.282 50.4525 33.9789C50.4525 38.8657 50.0302 39.288 54.374 39.288C57.2096 39.288 57.5113 38.9261 57.994 37.0558C58.4766 35.2458 59.1403 34.4012 60.9502 34.4012C62.7602 34.4012 63.9065 36.0905 64.8718 39.8914C66.7421 47.1915 65.7768 49.5445 62.5792 49.7255C61.0105 49.8461 60.1056 49.1825 59.0799 46.7692C58.3559 44.8989 57.6923 44.6576 54.1931 44.7782C50.2112 44.8386 50.4525 45.2006 50.4525 50.0874C50.4525 53.5264 50.8145 54.4917 52.8054 55.095C55.5807 55.8793 56.8476 56.2413 56.8476 58.8959C56.8476 62.9985 55.0377 63.3001 46.2292 63.3001C37.4208 63.3001 35.6712 62.9985 35.4902 59.5595Z"
        />
        <path
          fill="currentColor"
          d="M76.7572 47.3726C76.7572 38.0211 82.4887 29.3937 93.4088 29.3937C102.459 29.3937 108.19 35.0045 108.19 44.1146C108.19 48.1569 107.285 48.8205 102.64 48.8205H87.1946C86.2293 48.8205 85.9276 49.1222 85.9879 50.0875C86.0483 53.0438 90.2112 55.7587 95.8824 55.7587C99.6833 55.7587 101.855 54.8537 103.364 54.0694C104.208 53.6471 104.872 53.1644 105.535 53.1644C106.802 53.1644 106.923 54.7934 106.501 56.362C105.294 61.0076 100.106 64.0241 93.3484 64.0241C83.454 64.0241 76.7572 57.0257 76.7572 47.3726ZM98.8386 42.3047C98.8386 38.9864 95.8824 35.9698 92.0211 35.9698C88.5822 35.9698 85.9276 38.7451 86.1086 42.908C86.1689 43.8733 86.7119 43.5113 90.5732 43.5113C98.175 43.5113 98.8386 43.8733 98.8386 42.3047Z"
        />
        <path
          fill="currentColor"
          d="M111.086 47.3726C111.086 38.0211 116.817 29.3937 127.738 29.3937C136.787 29.3937 142.519 35.0045 142.519 44.1146C142.519 48.1569 141.614 48.8205 136.968 48.8205H121.523C120.558 48.8205 120.256 49.1222 120.317 50.0875C120.377 53.0438 124.54 55.7587 130.211 55.7587C134.012 55.7587 136.184 54.8537 137.692 54.0694C138.537 53.6471 139.201 53.1644 139.864 53.1644C141.131 53.1644 141.252 54.7934 140.83 56.362C139.623 61.0076 134.434 64.0241 127.677 64.0241C117.783 64.0241 111.086 57.0257 111.086 47.3726ZM133.167 42.3047C133.167 38.9864 130.211 35.9698 126.35 35.9698C122.911 35.9698 120.256 38.7451 120.437 42.908C120.498 43.8733 121.041 43.5113 124.902 43.5113C132.504 43.5113 133.167 43.8733 133.167 42.3047Z"
        />
        <path
          fill="currentColor"
          d="M144.63 59.2579C144.63 57.629 144.992 56.724 147.044 55.5173C148.552 54.6727 148.733 53.6471 148.914 50.1478C148.974 48.2775 149.035 41.5204 149.035 37.7798C149.035 34.8839 149.035 29.7557 148.974 27.5837C148.914 25.8944 148.612 25.5324 147.164 25.5324C145.173 25.5324 143.243 25.5928 143.243 23.7225C143.243 21.9729 144.449 20.7059 146.078 19.1373C147.828 17.448 150.241 16 152.956 16C156.274 16 158.748 17.9909 158.748 21.6712C158.748 23.4811 158.567 26.3167 158.326 30.6606C158.145 35.6682 157.662 46.5279 157.903 51.2941C157.964 53.7677 158.084 54.371 159.713 55.276C161.161 56.181 162.066 56.905 162.066 58.8356C162.066 61.9125 160.437 63.1795 153.499 63.1795C146.923 63.1795 144.63 62.9985 144.63 59.2579Z"
        />
        <path
          fill="currentColor"
          d="M186.682 59.5596C186.682 57.3273 187.587 56.362 189.879 55.095C192.051 53.828 192.896 53.4057 192.896 49.9065V30.6606C192.896 28.73 192.655 28.4887 189.457 28.4887C186.018 28.4887 186.199 28.73 185.475 31.1433C183.846 36.2112 182.519 38.0814 179.382 38.0814C176.968 38.0814 174.857 36.8145 174.857 34.1599C174.857 32.6516 175.46 30.4193 176.305 27.282C178.235 20.8265 179.623 19.6199 182.579 19.6199C185.535 19.6199 186.199 20.7662 189.698 20.7662H206.893C210.332 20.7662 211.056 19.5596 213.771 19.5596C216.968 19.5596 218.296 21.0075 220.166 27.101C221.131 30.178 221.614 32.4102 221.614 33.9185C221.614 36.8748 219.683 38.1418 217.27 38.1418C214.072 38.1418 212.624 35.9095 211.056 31.0829C210.151 28.549 210.392 28.4887 206.29 28.4887C203.695 28.4887 203.213 28.8507 203.213 30.902V49.7255C203.213 53.2247 203.756 53.828 205.626 54.7934C207.738 55.6983 209.065 56.3016 209.065 59.0769C209.065 63.1795 206.772 63.3605 197.662 63.3605C188.552 63.3605 186.682 62.9985 186.682 59.5596Z"
        />
        <path
          fill="currentColor"
          d="M224.63 59.9216C224.63 58.172 225.354 56.9653 227.164 56.0603C228.673 55.3967 229.276 54.733 229.397 52.0784C229.638 49.0618 229.578 44.4766 229.578 40.9774C229.578 39.1674 228.854 38.8658 227.345 38.8658C225.234 38.8658 223.243 38.9864 223.243 36.8145C223.243 34.9442 224.389 33.4962 226.501 31.9879C228.19 30.7813 230.181 29.816 232.112 29.816C234.465 29.816 235.913 31.083 235.913 33.3756C235.913 35.5475 235.611 36.0302 236.516 36.0302C237.24 36.0302 237.36 35.1855 238.929 32.9533C240.317 30.8416 242.247 29.3937 245.747 29.3937C250.694 29.3937 253.529 32.7119 253.469 37.7798C253.469 41.7617 251.9 44.175 247.979 44.175C246.229 44.175 245.143 43.3303 244.6 41.641C243.937 39.5294 243.575 38.1418 242.066 38.1418C239.351 38.1418 238.205 41.5204 238.205 44.0543C238.145 47.1312 238.205 48.5792 238.326 51.5958C238.446 54.1901 238.688 54.733 241.101 55.5174C243.876 56.4827 245.143 56.905 245.143 59.4993C245.143 62.5762 243.273 63.1795 235.128 63.1795C226.863 63.2398 224.63 63.4208 224.63 59.9216Z"
        />
        <path
          fill="currentColor"
          d="M255.279 59.5596C255.279 58.353 255.701 57.086 257.632 56.1207C259.201 55.2157 259.683 54.4314 259.804 51.9578C259.925 49.0619 259.864 44.356 259.864 40.6757C259.864 38.9864 259.502 38.6848 258.356 38.6848C255.822 38.6848 254.917 38.1418 254.917 36.7542C254.917 34.9442 256.063 33.6772 258.054 32.2896C260.226 30.721 261.976 30.0573 264.329 30.0573C268.371 30.0573 269.759 31.5053 269.759 34.5822C269.759 37.6591 268.793 40.1327 268.793 43.7527C268.733 46.0453 268.793 48.5189 268.914 52.3801C269.035 54.3107 269.155 55.095 270.543 55.9397C272.051 56.7843 272.775 57.629 272.775 59.5596C272.775 62.3952 271.026 63.1795 264.449 63.1795C257.632 63.2398 255.279 63.4812 255.279 59.5596ZM257.692 21.9729C257.692 19.0166 259.864 16.724 264.087 16.724C268.311 16.724 270.543 18.9563 270.543 22.0332C270.543 24.9291 268.612 26.7994 264.389 26.7994C260.166 26.7994 257.692 24.9895 257.692 21.9729Z"
        />
        <path
          fill="currentColor"
          d="M275.128 72.6516C275.128 69.7557 275.973 69.2127 277.903 68.006C279.532 67.1011 279.713 66.1358 279.713 62.2142V42.6667C279.713 39.8311 279.532 38.9261 277.722 38.9261C275.49 38.9261 273.379 38.9864 273.379 36.8145C273.318 34.5822 274.947 33.1342 277.119 31.5656C278.627 30.4193 280.437 29.5143 282.368 29.5143C284.781 29.5143 286.229 30.8416 286.229 33.1342C286.229 35.1855 286.229 35.8492 286.953 35.8492C287.858 35.8492 288.1 34.7029 289.185 33.3756C291.056 30.9623 293.891 29.3937 298.115 29.3937C306.32 29.3937 311.629 35.9698 311.629 45.4419C311.629 55.3967 306.923 63.1795 297.33 63.1795C294.374 63.1795 292.624 62.5158 291.357 61.8522C290.151 61.1885 289.547 60.5249 288.884 60.5249C287.798 60.5852 288.039 61.6712 288.039 64.6878C288.1 67.9457 288.16 68.1267 289.909 68.8507C291.961 69.816 293.107 70.54 293.107 73.0739C293.107 75.4269 291.659 76.5732 287.195 76.5732C281.101 76.6335 275.128 76.1508 275.128 72.6516ZM301.855 46.5279C301.373 40.9774 298.839 37.2971 294.374 37.7195C289.789 38.1418 288.16 42.4857 288.643 47.3122C289.306 53.3454 291.719 56.1207 296.244 55.6983C300.588 55.3967 302.398 52.0181 301.855 46.5279Z"
        />
        <path
          fill="currentColor"
          d="M35.7315 114.069C32.0513 110.268 30 104.959 30 98.4434C30 91.3243 32.3529 85.5928 36.2745 81.6109C39.8341 77.991 44.721 76 50.2715 76C54.555 76 57.1493 77.3273 59.0799 79.1976C59.8643 79.8612 60.2866 80.2232 60.9502 80.2232C62.2775 80.2232 60.5882 76.4827 64.7511 76.4827C66.9231 76.4827 67.4057 77.991 69.3967 82.5762C70.6637 85.3514 71.2066 87.2821 71.2066 88.8507C71.2066 91.2036 70.0603 92.4103 68.1297 93.0136C63.4842 94.4012 62.0362 92.7722 58.7783 88.73C56.365 85.8944 53.1071 84.1448 49.8492 84.1448C47.0739 84.1448 44.54 85.2308 42.9713 86.8597C40.9804 88.9713 40.0151 91.9276 40.0151 95.0045C40.0151 99.2881 41.644 103.632 44.6606 106.588C47.0136 108.941 50.2112 110.389 54.736 110.389C58.2956 110.389 61.4329 109.424 64.0875 108.278C65.8371 107.493 67.0437 106.528 68.1297 107.373C69.0347 108.036 69.0347 109.303 68.4917 110.57C66.4404 115.216 62.1569 120.103 51.5385 120.103C44.902 120.103 39.4118 117.931 35.7315 114.069Z"
        />
        <path
          fill="currentColor"
          d="M74.0422 103.059C74.0422 93.2851 80.1357 85.6833 90.7541 85.7436C101.614 85.804 107.044 93.2851 107.044 103.059C107.044 112.833 100.89 120.193 90.5128 120.193C79.4118 120.193 74.0422 112.893 74.0422 103.059ZM97.632 103.33C96.7873 97.2972 94.1931 93.1343 89.2459 93.5566C85.083 93.9186 82.8507 97.5385 83.635 103.149C84.359 108.7 87.3756 112.682 92.2021 112.259C96.3047 111.837 98.356 108.64 97.632 103.33Z"
        />
        <path
          fill="currentColor"
          d="M113.077 110.751C113.077 105.683 113.922 100.917 113.922 97.6592C113.922 96.1509 113.741 95.3665 112.413 95.3665C110.483 95.3665 108.492 95.5475 108.492 93.5566C108.492 91.5656 110.121 89.816 112.413 88.3077C114.284 87.0407 116.516 86.0754 118.748 86.0754C121.825 86.0754 123.514 87.5837 123.514 90.6003C123.514 96.3922 122.549 102.124 122.549 108.579C122.549 111.053 124.118 112.139 126.591 112.139C130.935 112.139 132.986 107.674 133.228 103.692C133.348 101.4 133.469 98.7451 133.469 96.8145C133.469 95.6079 133.107 95.0046 132.142 95.0046C130.392 95.0046 128.522 94.9442 128.522 93.1343C128.522 91.1433 130.271 89.454 132.504 88.0061C134.314 86.9201 136.244 86.0754 138.597 86.0754C141.554 86.0754 143.182 87.3424 143.182 90.2383C143.182 94.9442 142.097 102.365 142.097 108.7C142.097 110.389 142.76 111.294 143.967 111.294C144.872 111.294 145.294 111.053 146.139 111.053C146.983 111.053 147.707 111.837 147.707 113.285C147.707 116.724 144.57 119.861 140.588 119.861C136.606 119.861 135.4 118.232 134.977 115.457C134.796 114.311 134.615 113.768 134.133 113.768C133.288 113.768 133.107 114.854 131.78 116.664C130.513 118.413 127.798 120.163 123.454 120.163C116.938 120.163 113.077 116.724 113.077 110.751Z"
        />
        <path
          fill="currentColor"
          d="M149.457 116C149.457 114.311 150.181 113.104 151.931 112.078C153.922 110.992 154.344 110.389 154.344 107.795V97.2368C154.344 95.4269 153.861 95.2459 152.051 95.1855C149.879 95.1855 148.552 94.7632 148.552 92.6516C148.552 91.264 149.517 89.816 151.931 88.1267C153.741 86.8597 155.611 86.0754 157.421 86.0754C160.196 86.0754 161.704 87.4027 161.704 89.454C161.704 90.721 161.403 91.988 162.247 91.988C163.092 91.988 163.092 91.083 164.721 89.092C166.109 87.4631 168.582 85.8341 172.564 85.8341C179.201 85.8341 183.544 89.816 183.544 95.7889C183.544 99.7104 183.122 102.968 183.122 107.493C183.122 110.57 183.363 111.475 184.992 112.38C186.621 113.345 187.406 114.431 187.406 115.94C187.406 118.896 186.199 119.258 178.175 119.258C171.297 119.258 170.211 118.896 170.211 115.698C170.211 114.009 170.754 113.225 172.323 112.32C173.831 111.294 174.012 110.51 174.012 107.976V99.2278C174.012 95.7889 172.926 93.7979 169.789 93.7979C165.023 93.7979 163.213 98.1418 163.213 102.848V108.217C163.213 110.811 163.273 111.294 164.48 112.139C166.048 113.164 166.652 114.069 166.652 115.638C166.652 118.896 165.626 119.258 158.748 119.258C150.664 119.258 149.457 118.896 149.457 116Z"
        />
        <path
          fill="currentColor"
          d="M191.207 117.267C190.241 114.793 189.759 113.345 189.759 112.018C189.759 110.57 190.724 109.544 191.931 109.062C194.284 107.976 195.611 108.338 198.627 110.63C201.463 113.104 204.118 114.19 206.712 114.19C209.306 114.19 210.392 113.044 210.392 111.354C210.392 109.122 208.281 108.338 203.876 107.433C200.618 106.769 195.128 105.925 192.655 103.33C191.086 101.641 190.241 99.5294 190.241 96.8748C190.241 89.9969 195.973 85.9547 202.549 85.9547C205.747 85.9547 207.798 86.8597 209.427 88.006C210.03 88.4283 210.694 88.911 211.418 88.911C212.866 88.911 211.599 85.9547 214.615 85.9547C216.727 85.9547 216.968 87.2217 217.873 89.273C218.899 91.5052 219.261 93.1945 219.261 94.3408C219.261 95.4268 218.778 96.2111 217.994 96.6938C215.641 98.2021 214.253 97.9607 210.814 95.4871C208.462 93.7978 205.324 92.1689 202.73 92.1689C200.136 92.1689 198.808 93.4962 198.808 95.1855C198.808 96.6938 199.955 97.2368 203.333 97.8401C210.814 99.2277 214.072 99.9517 216.606 102.606C218.296 104.356 219.261 106.287 219.261 109.484C219.261 116.483 213.771 120.163 206.772 120.163C203.575 120.163 201.946 119.379 200.437 118.655C199.17 118.051 198.748 117.327 197.964 117.327C196.395 117.327 197.843 120.284 194.284 120.284C192.534 120.284 191.931 119.258 191.207 117.267Z"
        />
        <path
          fill="currentColor"
          d="M222.881 103.632C222.881 94.2805 228.612 85.6531 239.532 85.6531C248.582 85.6531 254.314 91.2639 254.314 100.374C254.314 104.416 253.409 105.08 248.763 105.08H233.318C232.353 105.08 232.051 105.382 232.112 106.347C232.172 109.303 236.335 112.018 242.006 112.018C245.807 112.018 247.979 111.113 249.487 110.329C250.332 109.906 250.995 109.424 251.659 109.424C252.926 109.424 253.047 111.053 252.624 112.621C251.418 117.267 246.229 120.284 239.472 120.284C229.578 120.284 222.881 113.285 222.881 103.632ZM244.962 98.5339C244.962 95.2157 242.006 92.1991 238.145 92.1991C234.706 92.1991 232.051 94.9743 232.232 99.1372C232.293 100.103 232.836 99.7406 236.697 99.7406C244.299 99.7406 244.962 100.103 244.962 98.5339Z"
        />
        <path
          fill="currentColor"
          d="M256.425 115.517C256.425 113.888 256.787 112.983 258.839 111.777C260.347 110.932 260.528 109.906 260.709 106.407C260.769 104.537 260.83 97.7798 260.83 94.0392C260.83 91.1433 260.83 86.0151 260.769 83.8431C260.709 82.1538 260.407 81.7918 258.959 81.7918C256.968 81.7918 255.038 81.8522 255.038 79.9819C255.038 78.2322 256.244 76.9653 257.873 75.3967C259.623 73.7074 262.036 72.2594 264.751 72.2594C268.069 72.2594 270.543 74.2504 270.543 77.9306C270.543 79.7405 270.362 82.5761 270.121 86.92C269.94 91.9276 269.457 102.787 269.698 107.554C269.759 110.027 269.879 110.63 271.508 111.535C272.956 112.44 273.861 113.164 273.861 115.095C273.861 118.172 272.232 119.439 265.294 119.439C258.718 119.439 256.425 119.258 256.425 115.517Z"
        />
        <path
          fill="currentColor"
          d="M275.973 115.819C275.973 114.612 276.395 113.345 278.326 112.38C279.894 111.475 280.377 110.691 280.498 108.217C280.618 105.321 280.558 100.615 280.558 96.9352C280.558 95.2459 280.196 94.9442 279.05 94.9442C276.516 94.9442 275.611 94.4012 275.611 93.0136C275.611 91.2037 276.757 89.9367 278.748 88.549C280.92 86.9804 282.67 86.3168 285.023 86.3168C289.065 86.3168 290.452 87.7647 290.452 90.8417C290.452 93.9186 289.487 96.3922 289.487 100.012C289.427 102.305 289.487 104.778 289.608 108.64C289.728 110.57 289.849 111.354 291.237 112.199C292.745 113.044 293.469 113.888 293.469 115.819C293.469 118.655 291.719 119.439 285.143 119.439C278.326 119.499 275.973 119.741 275.973 115.819Z"
        />
        <path
          fill="currentColor"
          d="M296.003 116C296.003 114.311 296.727 113.104 298.477 112.078C300.468 110.992 300.89 110.389 300.89 107.795V97.2368C300.89 95.4269 300.407 95.2459 298.597 95.1855C296.425 95.1855 295.098 94.7632 295.098 92.6516C295.098 91.264 296.063 89.816 298.477 88.1267C300.287 86.8597 302.157 86.0754 303.967 86.0754C306.742 86.0754 308.25 87.4027 308.25 89.454C308.25 90.721 307.949 91.988 308.793 91.988C309.638 91.988 309.638 91.083 311.267 89.092C312.655 87.4631 315.128 85.8341 319.11 85.8341C325.747 85.8341 330.09 89.816 330.09 95.7889C330.09 99.7104 329.668 102.968 329.668 107.493C329.668 110.57 329.909 111.475 331.538 112.38C333.167 113.345 333.952 114.431 333.952 115.94C333.952 118.896 332.745 119.258 324.721 119.258C317.843 119.258 316.757 118.896 316.757 115.698C316.757 114.009 317.3 113.225 318.869 112.32C320.377 111.294 320.558 110.51 320.558 107.976V99.2278C320.558 95.7889 319.472 93.7979 316.335 93.7979C311.569 93.7979 309.759 98.1418 309.759 102.848V108.217C309.759 110.811 309.819 111.294 311.026 112.139C312.594 113.164 313.198 114.069 313.198 115.638C313.198 118.896 312.172 119.258 305.294 119.258C297.21 119.258 296.003 118.896 296.003 116Z"
        />
        <path
          fill="currentColor"
          d="M334.736 126.739C334.736 124.024 336.425 122.214 339.14 121.188C340.226 120.706 341.071 120.585 341.071 119.922C341.071 119.318 340.588 119.198 339.563 118.534C337.692 117.508 337.029 116.543 337.029 114.492C337.029 112.199 338.597 110.811 341.011 110.087C342.459 109.605 342.941 109.544 342.881 108.76C342.881 108.036 342.338 107.915 341.252 107.373C337.873 105.683 336.244 102.365 336.244 98.5037C336.244 90.4796 341.674 85.834 350.181 85.834C352.836 85.834 353.801 86.3167 354.706 86.4374C355.43 86.558 355.792 86.377 355.852 85.5324C355.852 84.5067 355.792 83.4208 355.792 82.5761C355.792 79.6802 357.903 76.8446 362.127 76.8446C366.35 76.8446 369.668 79.6199 369.668 83.4208C369.668 85.2911 368.884 86.739 366.591 86.739C365.626 86.739 364.721 86.4374 363.997 86.1357C363.092 85.834 362.308 85.4721 361.463 85.4721C360.437 85.4721 360.136 86.196 360.136 87.463C360.136 88.2473 360.558 88.7903 361.403 89.5746C364.057 91.7466 365.385 94.7632 365.385 98.1417C365.385 104.115 361.463 108.881 352.775 109.665C348.13 110.087 346.742 110.389 346.742 112.38C346.742 114.793 348.974 113.888 355.671 113.888C364.118 113.828 368.703 116.302 368.582 124.507C368.462 130.54 361.161 133.557 349.638 133.557C340.045 133.557 334.736 131.264 334.736 126.739ZM359.532 124.627C359.532 121.912 356.697 121.249 351.87 121.188C346.32 121.128 344.027 121.188 344.027 123.903C344.027 126.618 346.139 127.584 350.483 127.644C357.3 127.704 359.532 127.161 359.532 124.627ZM356.516 99.0769C356.516 95.5776 354.103 91.8974 350.241 91.8974C346.802 91.8974 344.57 94.1297 344.63 97.0256C344.63 100.525 347.526 103.903 350.965 103.903C354.404 103.903 356.516 101.973 356.516 99.0769Z"
        />
      </svg>
      <div className={s.scrim} />
      <video className={s.video} autoPlay loop muted>
        <source src={sample} type="video/mp4" />
      </video>
    </div>
  );
};

window.onload = () => {
  const domNode = document.getElementById("root");
  const root = createRoot(domNode);
  root.render(<App />);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
